import { Button } from '@thg-commerce/gravity-elements'
import {
  css,
  spacing,
  styled,
  Text,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'

export const Container = styled.div`
  position: relative;
`

export const Title = styled.h2<{ alignment?: string }>`
  ${Text('large1', 'alternate')};
  margin: ${spacing(2)} 0 ${spacing(2)};
  text-align: ${(props) => props.alignment || 'center'};
`

export const PlayButton = styled.button`
  position: absolute;
  inset: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
`

export const VideoContainer = styled.video<{ isVisible: boolean }>`
  ${({ isVisible }) => !isVisible && 'visibility: hidden'};

  height: ${(props) =>
    props.theme.widget.GlobalVideoHeroBannerWidget.layout.height
      ? `calc(${props.theme.widget.GlobalVideoHeroBannerWidget.layout.height?.widgetHeight} - ${props.theme.widget.GlobalVideoHeroBannerWidget.layout.height?.headerHeight});`
      : 'auto'};
  object-fit: ${(props) =>
    props.theme.widget.GlobalVideoHeroBannerWidget.layout.height
      ? 'cover'
      : 'fill'};

  &::cue {
    ${Text('medium1', 'default')};
    color: ${(props) => props.theme.colors.palette.greys.white};
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 0px;
  }
`

export const PreviewImageContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  ${zIndex(ZIndexLevel.Base)}

  ${({ isVisible }) =>
    !isVisible &&
    'visibility: hidden; opacity: 0; transition: visibility 0s 0.5s, opacity 0.5s linear;'}
`

export const PreviewImage = styled.div`
  position: relative;
`

const ButtonsStyle = css`
  width: 30px;
  height: 30px;
  border-radius: 15px;
`
export const TranscriptTriggerButton = styled(Button)<{
  isVisible: boolean
}>`
  position: absolute;
  ${zIndex(ZIndexLevel.Low)}
  bottom: 32px;
  left: 130px;
  ${ButtonsStyle}
  background-color: #242020;
  &:hover {
    ${ButtonsStyle}
    outline: 2px solid ${(props) => props.theme.colors.palette.greys.white};
  }
  &:focus {
    ${ButtonsStyle}
    outline: 2px solid ${(props) => props.theme.colors.palette.greys.white};
  }
  /* Firefox */
  @supports (-moz-appearance: none) {
    left: auto;
    right: 55px;
    bottom: 45px;
  }
  /* Safari */
  @supports (-webkit-backdrop-filter: none) {
    left: 15px;
    bottom: 45px;
  }
`
export const CaptionTriggerButton = styled(Button)<{
  isVisible: boolean
}>`
  position: absolute;
  ${zIndex(ZIndexLevel.Low)}
  bottom: 32px;
  left: 170px;
  ${ButtonsStyle}
  background-color: #242020;
  &:hover {
    ${ButtonsStyle}
    outline: 2px solid ${(props) => props.theme.colors.palette.greys.white};
  }
  &:focus {
    ${ButtonsStyle}
    outline: 2px solid ${(props) => props.theme.colors.palette.greys.white};
  }
  /* Firefox */
  @supports (-moz-appearance: none) {
    left: auto;
    right: 15px;
    bottom: 45px;
  }
  /* Safari */
  @supports (-webkit-backdrop-filter: none) {
    left: 55px;
    bottom: 45px;
  }
`
