import * as React from 'react'

import { ModalRenderer } from '@thg-commerce/enterprise-modal'
import { ModalRendererProps } from '@thg-commerce/enterprise-modal/src/ModalRenderer/ModalRenderer'
import { SafeHtml } from '@thg-commerce/gravity-elements'
import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

const Title = styled.h1`
  ${Text('medium1', 'alternate')}
  margin-bottom: ${spacing(2)};
`

const Content = styled(SafeHtml)<{ backgroundColor?: string; color?: string }>`
  margin-bottom: ${spacing(2)};
  display: block;
  width: 100%;
  overflow-x: scroll;
`

export interface TranscriptModalProps {
  i18nText: {
    closeI18nText: ModalRendererProps['closeI18nText']
    title: string
  }
  content: string | undefined
  onClose: () => void
}

export const VideoTranscriptModal = (props: TranscriptModalProps) => {
  return (
    <ModalRenderer
      closeI18nText={props.i18nText.closeI18nText}
      gridColSpan={[12, 12, 10, 6]}
      stickyHeader={true}
      showHeader={true}
      onClose={props.onClose}
      verticalAlignment="center"
      data-testid="video-transcript-modal"
      renderContent={() => {
        return (
          <React.Fragment>
            <Title>{props.i18nText.title}</Title>
            <Content content={props.content} />
          </React.Fragment>
        )
      }}
    />
  )
}
