import React from 'react'

import { GlobalVideoHeroBannerWidget } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  VideoHeroBanner,
  VideoHeroBannerProps,
} from '@thg-commerce/enterprise-widget-video-hero-banner'
import { mq, spacing, styled } from '@thg-commerce/gravity-theme'

import { BaseWidgetProps } from '../types'

export type VideoHeroBannerRendererProps = BaseWidgetProps &
  Omit<GlobalVideoHeroBannerWidget, 'query'>

const StyledVideoHeroBanner = styled(VideoHeroBanner)`
  margin: 0 auto ${spacing(4)}
  max-width: 1920px;
  
    ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-bottom: ${spacing(10)};
  }
`

export const VideoHeroBannerRenderer = (
  props: VideoHeroBannerRendererProps,
) => {
  if (!(props.videoAssetPathMp4 || props.videoAssetPathWebm)) {
    return null
  }

  const videoHeroBannerProps: VideoHeroBannerProps = {
    videoTitle: { text: props.videoTitle || undefined },
    videoAssetPathMp4: props.videoAssetPathMp4 || undefined,
    videoAssetPathWebm: props.videoAssetPathWebm || undefined,
    previewImage: {
      urls: {
        small: props.videoPreviewImageMobile,
        medium: props.VideoPreviewImageTablet,
        large: props.videoPreviewImage,
      },
    },
    muteVideo: props.muteVideo === 'true',
    autoplayVideo: props.autoplayVideo === 'true',
    transcript: props.transcript?.content[0].content || undefined,
    videoCaptionPathVTT: props.videoCaptionPathVTT || undefined,
  }

  return <StyledVideoHeroBanner {...videoHeroBannerProps} />
}
